// Instructions: //---------------

// Text container
//  [required]      1. data-id attr that is the same as the data-id attr of the button element, e.g. data-id='short-bio'
//  [required]      2. classes class='js-read-more read-more-text'
//  [required]      3. inline css for max-height, e.g. style="max-height:5.6em;"
//                          Set max height for desired effect, 5.6em equals to 4 rows in this case
//  [optional]      4. data-read-more-destination attr, e.g. data-read-more-destination="150",
//                          This is the location where the window will scroll to after collapsing the read more text.
//                          Value inside this attr is how many px to scroll above it to compensate for padding or fixed header, in this case 150px.
//                          This attr can be set to any element of the page, but it required the same data-id inside it.

//  Button
//  [required]      1. data-id attr that is the same as the data-id attr of the text container element element, e.g. data-id='short-bio'
//  [required]      2. class class='js-read-more-btn'
//  [required]      3. style="display:none;"

// Example: //-------------------

// TEXT:
// <p class="js-read-more js-read-more-destination read-more-text research-interest__text" style="max-height:5.6em;" data-id="short-bio" data-read-more-destination="150">
// {{ user.userAdditionalDetails.researchInterest }}
// </p>

// BUTTON:
// <button class="v2 view-all js-read-more-btn read-more-btn clear-fix" data-id="short-bio" data-toggled="false" style="display:none;">
//     Read more
// </button>

// CODE: // ---------------------

// Adding read more buttons on questions
// function readMoreDetection(id){
//   const readMoreBtn = $('.js-read-more-btn[data-id="' + id + '"]');
//   const readMoreContainer = $('.js-read-more[data-id="' + id + '"]');
//
//   // Comparing displayed height and actual div height
//   //  show read more button if actual height is larger
//   if(!(readMoreContainer)[0]) return;
//   if ($(readMoreContainer)[0].scrollHeight > $(readMoreContainer)[0].clientHeight){
//     const containerMaxHeight = $(readMoreContainer).css('max-height');
//     readMoreContainer.data('max-height', containerMaxHeight);
//     readMoreBtn.show();
//   }
// }

// function readMoreSetup(){
//   $.each($('.js-read-more'), function (i, el){
//     const id = $(this).data('id');
//     readMoreDetection(id);
//   });
// }

$(document)
  .ajaxSuccess(function (event, xhr, settings) {
    if(settings.url !== '/message/unread-count') {
      readMoreOnHeight();
    }
  })
  .ready(function (){
    readMoreOnHeight();
  });

// On click read more button
// $('body').on('click', '.js-read-more-btn', function (){
//   const id = $(this).data('id');
//   const toggled = $(this).data('toggled');
//   const readMoreContainer = $('.js-read-more[data-id="' + id + '"]')[0];
//   const readMoreContainerMaxHeight = $(readMoreContainer).data('max-height');
//   const readMoreDestination = $('[data-id="' + id + '"][data-read-more-destination]')[0] || readMoreContainer; // If there is no element with data-read-more-destination attribute, set destination to conainer element
//   const readMoreScrollOffset = $(readMoreDestination).data('read-more-destination') || 100; // How far to scroll above the title of the readMore (eg. 100px) to compensate for fixed header height that  blocks the view. If there is no data-read-more destination, it is set to conainer element and fixed offset of 100px
//   const topWindowPosition = window.pageYOffset || document.documentElement.scrollTop;
//
//   if (toggled){
//     $(readMoreContainer).css({ 'max-height': readMoreContainerMaxHeight });
//     $(this).text('Read more');
//
//     // If the top of the element is above the top of the window, scroll to it
//     if (topWindowPosition > $(readMoreDestination).offset().top - readMoreScrollOffset){
//       $('html, body').stop().animate({ scrollTop: $(readMoreDestination).offset().top - readMoreScrollOffset }, 500, 'swing');
//     }
//   } else {
//     $(readMoreContainer).css({ 'max-height': 'none' });
//     $(this).text('Read less');
//   }
//
//   $(this).data('toggled', !toggled);
// });

// new read more, on char count
// set data-read-more-character-count on desired number of characthers
// set data-additional-preview-text-style if you need additional css classes on read-more-preview
// set js-read-more-char-count read-more-cc classes to enable read more / less
// const readMoreOnCharSetup = (container) => {
//   if (container) {
//     container.find('.js-read-more-char-count').each(function () {
//       initReadMoreCharCount($(this));
//     });
//   } else {
//     $.each($('.js-read-more-char-count'), function () {
//       initReadMoreCharCount($(this));
//     });
//   }
// }
//
// function initReadMoreCharCount(readMoreContainer) {
//   let readMoreCharCount = readMoreContainer.data('read-more-character-count');
//   let additionalPreviewTextStyle = readMoreContainer.data('additional-preview-text-style');
//   let setNameMoreText = readMoreContainer.data('set-name-more-text');
//   let setNameLessText = readMoreContainer.data('set-name-less-text');
//
//   if(typeof additionalPreviewTextStyle == 'undefined') {
//     additionalPreviewTextStyle = '';
//   }
//
//   if (readMoreContainer.find('.fullText').length) {
//     return;
//   }
//   let target = readMoreContainer.find('p');
//
//   let readMoreText = "Read More";
//   let readLessText = "Read Less";
//
//   if(setNameMoreText) {
//     readMoreText = setNameMoreText;
//   }
//
//   if(setNameLessText) {
//     readLessText = setNameLessText;
//   }
//
//   // Check if text is longer then readMoreCharCount
//   // Wrap all paragragraphs into one with class to hide it
//   if(target.text().length > readMoreCharCount) {
//     let paragraphsWrap = readMoreContainer.contents().wrapAll('<p class="fullText"></p>');
//     let trimmedText = paragraphsWrap.text().trim();
//
//     readMoreContainer.find('.fullText').hide();
//
//     readMoreContainer.find('p').last().append('<a class="js-read-less-link read-more-cc__link">'+ readLessText+'</a>');
//     // trim text and whitespaces from beginning
//     trimmedText = trimmedText.substring(0, readMoreCharCount);
//     readMoreContainer.append('<p class="read-more-preview '+ additionalPreviewTextStyle +' ">'+trimmedText+'<span>...</span><a class="js-read-more-link read-more-cc__link">' + readMoreText + '</a></p>');
//   }
// }

// $(document).ready(function() {
//   readMoreOnCharSetup();
// })
//
// $(document).on('click', '.js-read-more-link', function(e) {
//   e.preventDefault();
//   $(this).closest('.read-more-preview').hide().prev().show();
// })
// $(document).on('click', '.js-read-less-link', function (e) {
//   e.preventDefault();
//   $(this).closest('.fullText').hide().next().show();
// });

// Read More functionality determined by height.
// [required]     1. js-read-more-container, read-more-container are classes to be used on elements
//                   where text should be taken under read more functionality.
// [optional]     2. data-collapsed_height parameter that is used to specify height other then default what is 88px.
//
function readMoreOnHeight() { // used readmore.js plugin
  $('.js-read-more-container').each(function (index, ele) {
    let readMoreContainer = $(ele);
    let collapsedHeight = readMoreContainer.data('collapsed_height') ? readMoreContainer.data('collapsed_height') : 88;
    let startOpen = readMoreContainer.data('start_open') ? true : false;
    readMoreContainer.readmore({
      speed: 75,
      moreLink: '<a class="read-more-link" href="#">Read more<span class="direction-arrow"><svg class="svg--directional--down"><use xlink:href="#svg--directional--down"></use></svg></span></a>',
      lessLink: '<a class="read-less-link" href="#">Read less<span class="direction-arrow"><svg class="svg--directional--up"><use xlink:href="#svg--directional--up"></use></svg></span></a>',
      collapsedHeight: collapsedHeight,
      startOpen: startOpen
    });
  });
}
